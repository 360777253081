import styled from "styled-components";

export const Wrapper = styled.div<{
  pillsDistanceFromTop: number;
}>`
  position: sticky;
  top: ${({ pillsDistanceFromTop }) => pillsDistanceFromTop}px;
  background-color: var(--fd-colors-core-white);
  z-index: 2;
`;

export const SelectionWrapper = styled.div<{ hasTransitionFinished: boolean }>`
  width: 100%;
  position: relative;
  visibility: ${({ hasTransitionFinished }) =>
    hasTransitionFinished ? `hidden` : `visible`};
`;

export const Selection = styled.div<{ width: number; left: number }>`
  position: absolute;
  width: ${({ width }) => width}px;
  height: 32px;
  top: var(--fd-space-space-2);
  left: ${({ left }) => left}px;
  border-radius: var(--fd-space-space-6);
  background-color: ${({ theme }) =>
    theme.colorTokens.component.button.buttonLink.content.base};
  transition:
    left 0.5s ease,
    width 0.5s ease;
`;

export const ScrollingMaskWrapper = styled.div`
  width: 100vw;
  height: 48px;
  position: relative;
  display: flex;
`;

export const ScrollingMask = styled.div`
  width: 100vw;
  height: 100%;
  top: 0;
  right: 0;
  position: absolute;
  background: linear-gradient(
    270deg,
    var(--fd-colors-core-white) 0%,
    rgba(5, 40, 90, 0) 20%
  );
  z-index: 2;
  pointer-events: none;
`;

export const ButtonsWrapper = styled.div<{ scrollingMaskOffset: number }>`
  position: relative;
  display: flex;
  padding: var(--fd-space-space-2);
  overflow-y: scroll;
  box-shadow: ${({ theme }) => theme.elevations.medium};

  & > div:last-child {
    button {
      margin-right: ${({ scrollingMaskOffset }) => scrollingMaskOffset}px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  height: 32px;

  button {
    height: inherit;
    padding: var(--fd-space-space-2);
    border-radius: var(--fd-space-space-6);
  }
`;

export const ButtonLabel = styled.span<{ isActive: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.s};
  white-space: nowrap;

  /* TODO css variable */
  color: ${({ theme, isActive }) =>
    isActive
      ? "var(--fd-colors-core-white)"
      : theme.colorTokens.component.button.buttonLink.content.base};
  transition: color 1s ease;
`;
