import React, { useCallback, useEffect, useRef, useState } from "react";
import ComponentSectionHeaderMask from "@fdr/static-ui/Masks/ComponentSectionHeaderMask";
import {
  SectionWrapper,
  SectionHeader,
  SectionContent
} from "./styled-components";
import HeaderComponent from "../HeaderComponent";
import { ComponentSectionProps } from "./types";

const ComponentSection = ({
  title,
  subtitle,
  hasHeaderSpace,
  children,
  isLoading,
  isNightMode,
  hasBackground,
  hasTopPadding,
  seeAllLink,
  qaLabel = "generic-section",
  onSectionVisibilityChanges,
  isFixed = false
}: ComponentSectionProps) => {
  const ref = useRef<HTMLElement>(null);
  const sectionWrapperRef = useRef<HTMLElement>(null);
  const [isVisible, setIsVisible] = useState(true);

  const checkAndSetVisibility = useCallback(
    (retryTime = 0, retryCount = 1, maxRetry = 5) => {
      if (retryCount > maxRetry) {
        setIsVisible(false);
        return;
      }

      setTimeout(() => {
        const hasContent = !!ref.current?.clientHeight;
        if (!hasContent) {
          setIsVisible(false);
          checkAndSetVisibility(retryTime + 1000, retryCount + 1);
        } else {
          setIsVisible(true);
          if (
            onSectionVisibilityChanges &&
            typeof onSectionVisibilityChanges === "function" &&
            !isFixed &&
            title
          ) {
            onSectionVisibilityChanges(sectionWrapperRef, true);
          }
        }
      }, retryTime);
    },
    [ref, sectionWrapperRef]
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Gap to wait component loading. Maybe on future create some state machine.
      checkAndSetVisibility();
    }
  }, []);

  useEffect(() => {
    // Mutation Observer that listens for sections that might appear/disappear.
    // During the mounting time, sections will eventually appear/disappear due to data changes.
    // We are inferring if the section is visible or not based on its children.
    // If there's no children, the section will be hidden
    // using CSS and :empty pseudo-class (check styled-components file)
    if (
      onSectionVisibilityChanges &&
      typeof onSectionVisibilityChanges === "function"
    ) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === "childList" &&
            !isLoading &&
            !isFixed &&
            title
          ) {
            if (mutation.addedNodes.length > 0) {
              setIsVisible(true);
              onSectionVisibilityChanges(sectionWrapperRef, true);
            }
            if (mutation.removedNodes.length > 0) {
              setIsVisible(false);
              onSectionVisibilityChanges(sectionWrapperRef, false);
            }
          }
        });
      });

      if (ref.current) {
        observer.observe(ref.current, { childList: true });
      }

      return () => {
        observer.disconnect();
      };
    }

    return undefined;
  }, [ref, sectionWrapperRef]);

  // Only render IF have children component
  if (!children) {
    return null;
  }

  const hasHeader = !!(title || subtitle);

  return (
    <SectionWrapper
      isNightMode={isNightMode}
      hasBackground={hasBackground}
      data-qa-label={qaLabel}
      isVisible={isVisible}
      className={
        isVisible ? "section-wrapper-visible" : "section-wrapper-hidden"
      }
      ref={sectionWrapperRef}
    >
      {hasHeader && (
        <SectionHeader data-qa-label={`${qaLabel}-header`}>
          {isLoading ? (
            <ComponentSectionHeaderMask />
          ) : (
            <HeaderComponent
              title={title}
              subtitle={subtitle}
              seeAllLink={seeAllLink}
            />
          )}
        </SectionHeader>
      )}
      <SectionContent
        ref={ref}
        hasHeader={hasHeader || !hasHeaderSpace}
        hasTopPadding={!!hasTopPadding}
        data-qa-label={`${qaLabel}-content`}
      >
        {children}
      </SectionContent>
    </SectionWrapper>
  );
};

export default ComponentSection;
