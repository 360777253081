import React from "react";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
    opacity: 1
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 1
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;
  height: 720px;
  animation: ${pulseAnimation} 1.25s infinite;
`;

export const Container = styled.div`
  background-color: var(--fd-colors-core-white);
`;

const HomepageMask = () => (
  <Container data-qa-label="homepageMask">
    <SVG>
      <g>
        <rect fill="var(--fd-colors-core-white)" height="100%" width="100%" />
        <rect fill="var(--fd-colors-core-white)" height="40" width="100%" />
        <rect
          width="120"
          height="8"
          x="8"
          y="16"
          rx="1"
          ry="1"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="100%"
          height="1"
          y="40"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="126"
          height="8"
          x="8"
          y="64"
          rx="1"
          ry="1"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="100%"
          height="1"
          y="96"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="86"
          height="8"
          x="8"
          y="120"
          rx="1"
          ry="1"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="100%"
          height="1"
          y="152"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="166"
          height="8"
          x="8"
          y="176"
          rx="1"
          ry="1"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="100%"
          height="1"
          y="208"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="246"
          height="8"
          x="8"
          y="232"
          rx="1"
          ry="1"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="100%"
          height="1"
          y="264"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="134"
          height="8"
          x="8"
          y="288"
          rx="1"
          ry="1"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="100%"
          height="1"
          y="320"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="190"
          height="8"
          x="8"
          y="344"
          rx="1"
          ry="1"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="100%"
          height="1"
          y="376"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="182"
          height="8"
          x="8"
          y="400"
          rx="1"
          ry="1"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="100%"
          height="1"
          y="432"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="222"
          height="8"
          x="8"
          y="456"
          rx="1"
          ry="1"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="100%"
          height="1"
          y="488"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="126"
          height="8"
          x="8"
          y="512"
          rx="1"
          ry="1"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="100%"
          height="1"
          y="544"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="86"
          height="8"
          x="8"
          y="568"
          rx="1"
          ry="1"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="100%"
          height="1"
          y="600"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="166"
          height="8"
          x="8"
          y="624"
          rx="1"
          ry="1"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="100%"
          height="1"
          y="656"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="246"
          height="8"
          x="8"
          y="680"
          rx="1"
          ry="1"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="100%"
          height="1"
          y="712"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
      </g>
    </SVG>
  </Container>
);

export default HomepageMask;
