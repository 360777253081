import React, {
  useRef,
  useEffect,
  useState,
  Dispatch,
  SetStateAction
} from "react";
import { Button } from "@tvg/design-system/web";
import PillsLoadingMask from "../../loadings/PillsLoadingMask";
import { PillNavProps, OriginalBtnsRects } from "./types";
import {
  Wrapper,
  SelectionWrapper,
  Selection,
  ScrollingMaskWrapper,
  ScrollingMask,
  ButtonsWrapper,
  ButtonWrapper,
  ButtonLabel
} from "./styled-components";

const MARGIN_OFFSET = 8;
const SCROLLING_MASK_OFFSET = 60;

const scrollTo = (
  elem: HTMLDivElement,
  pos: number,
  activePillPosSetter: Dispatch<SetStateAction<number>>,
  activePillPos: number,
  setHasTransitionFinished?: Dispatch<SetStateAction<boolean>>
) => {
  elem.scrollTo({
    left: pos,
    behavior: "smooth"
  });
  activePillPosSetter(activePillPos);

  if (setHasTransitionFinished && elem.scrollLeft === pos) {
    setHasTransitionFinished(true);
  }
};

const PillsNav = ({ pills, isLoading, pillsDistanceFromTop }: PillNavProps) => {
  const buttonsWrapperRef = useRef<HTMLDivElement>(null);
  const buttonRefs = useRef<(HTMLDivElement | null)[]>([]);
  const originalBtnsRects = useRef<OriginalBtnsRects[]>([]);
  const [activePillWidth, setActivePillWidth] = useState(0);
  const [activePillLeftPos, setActivePillLeftPos] = useState(0);
  const [hasTransitionFinished, setHasTransitionFinished] = useState(true);
  const [statePills, setPills] = useState(pills);
  const pillsTitle = statePills.map((pill) => pill.title);

  // Store the original DOMRect of each buttons before any scroll on the wrapper
  useEffect(() => {
    originalBtnsRects.current = buttonRefs.current.map((currRef) => {
      const currRect = currRef?.getBoundingClientRect();
      const buttonsWrapperScrollLeft =
        buttonsWrapperRef.current?.scrollLeft ?? 0;
      return {
        left: Math.floor((currRect?.left ?? 0) + buttonsWrapperScrollLeft),
        right: Math.floor((currRect?.right ?? 0) + buttonsWrapperScrollLeft)
      };
    }) as OriginalBtnsRects[];
  }, [buttonRefs.current.length, JSON.stringify(pillsTitle)]);

  // Sync Props to State to prevent animation flickering
  useEffect(() => {
    setPills(pills);
  }, [JSON.stringify(pills)]);

  // Hook for horizontal auto-scroll
  // The 'active pill' should never be hidden, and the wrapper should automatically scroll to ensure it remains visible
  // The 'active pill' should always be anchored to the far-left side when there's enough space to hide its immediate left pill
  // When none of the above conditions are met, we ensure that the 'active pill' is visible
  useEffect(() => {
    if (buttonsWrapperRef.current) {
      const buttonsWrapper = buttonsWrapperRef.current;
      const activePillIndex = pills.findIndex(({ isActive }) => isActive);
      const activePillOriginalRect =
        buttonRefs.current[activePillIndex]?.getBoundingClientRect();

      if (activePillOriginalRect !== undefined) {
        const buttonsWrapperRectWidth =
          buttonsWrapper.getBoundingClientRect().width;
        // Scroll if there's enough space to hide its immediate left pill
        const hasSpaceToHideImmediateLeftPill =
          originalBtnsRects.current?.[activePillIndex]?.left + MARGIN_OFFSET <=
          buttonsWrapper.scrollWidth - buttonsWrapperRectWidth;
        // Scroll if the 'active pill' is hidden behind the Scrolling Mask
        const isActivePillBehindScrollingMask =
          activePillOriginalRect.right + SCROLLING_MASK_OFFSET >
          buttonsWrapperRectWidth;

        setActivePillWidth(
          activePillIndex === pills.length - 1
            ? activePillOriginalRect.width - SCROLLING_MASK_OFFSET
            : activePillOriginalRect.width
        );

        setHasTransitionFinished(false);

        if (hasSpaceToHideImmediateLeftPill) {
          const scrollToLeftPos =
            buttonsWrapper.scrollLeft +
            (activePillOriginalRect.left - MARGIN_OFFSET);

          scrollTo(
            buttonsWrapper,
            scrollToLeftPos,
            setActivePillLeftPos,
            MARGIN_OFFSET,
            setHasTransitionFinished
          );
        } else if (isActivePillBehindScrollingMask) {
          const scrollToLeftPos =
            buttonsWrapper.scrollLeft + activePillOriginalRect.left;
          const newActivePillLeftPos =
            activePillOriginalRect.left -
            (buttonsWrapper.scrollWidth -
              buttonsWrapperRectWidth -
              buttonsWrapper.scrollLeft);

          scrollTo(
            buttonsWrapper,
            scrollToLeftPos,
            setActivePillLeftPos,
            newActivePillLeftPos
          );
        } else {
          setActivePillLeftPos(activePillOriginalRect.left);
        }
      }
    }
  }, [JSON.stringify(pills), isLoading]);

  if (statePills?.every((pill) => !pill.isActive) || isLoading) {
    return <PillsLoadingMask qaLabel="pills-loading-mask" />;
  }

  return (
    <Wrapper pillsDistanceFromTop={pillsDistanceFromTop}>
      <SelectionWrapper hasTransitionFinished={hasTransitionFinished}>
        <Selection
          width={activePillWidth}
          left={activePillLeftPos}
          onTransitionEnd={() => {
            setHasTransitionFinished(true);
          }}
        />
      </SelectionWrapper>
      <ScrollingMaskWrapper>
        <ScrollingMask />
        <ButtonsWrapper
          ref={buttonsWrapperRef}
          scrollingMaskOffset={SCROLLING_MASK_OFFSET}
        >
          {statePills &&
            statePills
              .filter(({ title }) => !!title)
              .map(({ name, title, isActive, onClick }, index) => (
                <ButtonWrapper
                  key={`pill-btn-${name}-${index.toString()}`}
                  ref={(ref) => {
                    buttonRefs.current[index] = ref;
                  }}
                >
                  <Button
                    qaLabel={`pill-nav-${name?.toLocaleLowerCase()}`}
                    variant={
                      isActive && hasTransitionFinished ? "primary" : "tertiary"
                    }
                    onClick={onClick}
                  >
                    <ButtonLabel isActive={isActive}>{title}</ButtonLabel>
                  </Button>
                </ButtonWrapper>
              ))}
        </ButtonsWrapper>
      </ScrollingMaskWrapper>
    </Wrapper>
  );
};

export default PillsNav;
