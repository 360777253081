import {
  ComponentMapping,
  HomepageStoryblokConfig,
  HomepageTemplate,
  SectionConfigType,
  HomepageStoryblokMobileConfig
} from "../types";
import componentsMapping from "../componentsMapping";

export const renderComponents = (
  groupName: string,
  componentsGroups: HomepageTemplate
) => {
  if (
    !componentsGroups ||
    !componentsGroups[groupName as keyof HomepageTemplate]
  ) {
    return null;
  }

  const groupComponents = componentsGroups[groupName as keyof HomepageTemplate];

  if (!Array.isArray(groupComponents)) {
    return null;
  }

  return groupComponents
    .map((componentObj) => {
      const componentToRender = (componentsMapping as ComponentMapping)[
        componentObj.component as keyof ComponentMapping
      ]?.component;

      return componentToRender?.({ ...componentObj });
    })
    .filter(Boolean);
};

export const renderComponentsDesk = (
  templates: HomepageStoryblokConfig | HomepageStoryblokMobileConfig,
  groupName: SectionConfigType,
  selectedTemplate: string
) => {
  const componentsGroups = templates?.[selectedTemplate];

  return renderComponents(groupName, componentsGroups as HomepageTemplate);
};

export default renderComponentsDesk;
