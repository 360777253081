import React from "react";
import styled, { keyframes } from "styled-components";

interface Props {
  qaLabel: string;
}

const pulseAnimation = keyframes`
  0% {
    opacity: 1
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 1
  }
`;

export const SVG = styled.svg`
  animation: ${pulseAnimation} 1.25s infinite;
`;

export const Container = styled.div`
  background-color: var(--fd-colors-core-white);
  height: 48px;
`;

const PillsLoadingMask = ({ qaLabel }: Props) => (
  <Container>
    <SVG
      width="100%"
      height="48"
      fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
      data-qa-label={qaLabel}
    >
      <rect
        x="8"
        y="8"
        width="145"
        height="32"
        fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        rx="25"
      />
      <rect
        x="168"
        y="16"
        width="135"
        height="16"
        fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        rx="1"
      />
      <rect
        x="328"
        y="16"
        width="135"
        height="16"
        fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        rx="1"
      />
      <rect
        x="488"
        y="16"
        width="135"
        height="16"
        fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        rx="1"
      />
    </SVG>
  </Container>
);

export default PillsLoadingMask;
