import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const CardContainerLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  width: 318px;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.radii.s};
  border: 1px solid ${({ theme }) => theme.colorTokens.border.default};
`;

export const CardFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => css`
    border-radius: 0 0 ${theme.radii.s} ${theme.radii.s};
    padding: ${theme.space["space-4"]} ${theme.space["space-2"]}
      ${theme.space["space-4"]} ${theme.space["space-5"]};
    border-top: 1px solid ${theme.colorTokens.border.default};
    background: ${theme.colorTokens.background.surface};
  `}
`;
