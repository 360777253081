import styled, { css } from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";
import { breakpoints } from "@tvg/design-system/src";

export const SectionContent = styled.section<{
  hasHeader: boolean;
  hasTopPadding: boolean;
}>`
  ${({ hasHeader, hasTopPadding }) =>
    !hasTopPadding &&
    !hasHeader &&
    css`
      padding-top: var(--fd-space-space-2);
    `}
`;

export const SectionWrapper = styled.section<{
  isNightMode?: boolean;
  hasBackground?: boolean;
  isVisible?: boolean;
}>`
  width: 100%;
  margin-bottom: var(--fd-space-space-2);

  /* TODO css variables */
  background-color: ${({ isNightMode, hasBackground }) =>
    !hasBackground && isNightMode
      ? buildColor("navy", "default")
      : buildColor("white", "100")};
  ${({ isNightMode }) =>
    !isNightMode &&
    css`
      border-top: 1px solid ${buildColor("grey", "L2")};
      border-bottom: 1px solid ${buildColor("grey", "L2")};
    `}
  ${({ isVisible }) =>
    !isVisible &&
    css`
      margin-bottom: 0;
      height: 0;
      visibility: hidden;
    `}
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--fd-space-space-4);
  min-height: 48px;

  @media screen only and ${breakpoints.desktop.min.sm} {
    display: none;
  }
`;
