import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountNumber, getIsLogged } from "@urp/store-selectors";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { useAllowChoseBuiltInProfile } from "@urp/amplitude-experiments";
import {
  getHomepageConfigurableToggle,
  getHomepageTemplateUserPrefDesktop,
  getHomepageTemplateUserPrefMobile,
  getShowHPTemplateSelector
} from "@tvg/sh-lib-preferences/redux/selectors";
import {
  openPreferencesModalAction,
  setSelectedTab
} from "@tvg/sh-lib-preferences/redux/actions";
import { isFdrProduct } from "@tvg/sh-utils/mobileUtils";
import tvgConf from "@tvg/conf";
import { useNavigate } from "react-router";
import { setHPTemplateSelectorVisibility } from "@tvg/pref/src/components/HomepageConfig/utils";
import { Banner } from "@urp/banners/src";
import { BannersConfigContext } from "@urp/banners/src/types";
import { flatten, size } from "lodash";
import PreferencesModal from "@tvg/pref/src/components/PreferencesModal";
import { PreferencesTabEnum } from "@tvg/sh-lib-preferences/utils/types";
import { ComponentSection, PillsNav } from "./components";
import HomePageMask from "./loadings/HomepageMask";
import {
  getEnableHomepagePillsNav,
  getHomepageTemplates
} from "./store/selectors";
import {
  Bottom,
  HomeContainer,
  Main,
  SectionsWrapper,
  Side,
  StandaloneWrapper,
  TemplateSelectorContainer,
  TopSection,
  TemplateSelectorBackground
} from "./styled-components";
import useSections from "./hooks/useSections";
import { renderComponentsDesk, renderComponentsMobile } from "./utils";
import { SectionConfigType } from "./types";
import HPTemplateSelector from "./components/HPTemplateSelector";
import { VIEW_TYPE_SELECTOR } from "./components/HPTemplateSelector/types";

const Homepage = (): JSX.Element => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLogged);
  const enablePillsNav = useSelector(getEnableHomepagePillsNav);
  const navigate = useNavigate();
  const accountNumber = useSelector(getAccountNumber);
  const [selectedTemplate, setSelectedTemplate] = useState<string>("default");
  const allowChoseProfile = useAllowChoseBuiltInProfile() || false;

  const isMobileDevice = tvgConf().device === "mobile";
  const isTabletSize = useMediaQuery(breakpoints.tablet.max.sm);
  const isMobile = isMobileDevice || isTabletSize || isFdrProduct();

  const templates = useSelector(getHomepageTemplates);
  const userPrefTemplate = useSelector(
    isMobile
      ? getHomepageTemplateUserPrefMobile
      : getHomepageTemplateUserPrefDesktop
  );

  const { mainComponents, seoFooterComponent, componentsOrder } =
    renderComponentsMobile(selectedTemplate, templates) || {
      mainComponents: [],
      seoFooterComponent: []
    };

  const hasComponentLoaded = size(flatten(Object.values(mainComponents)));

  const showHPTemplateSelector = useSelector(getShowHPTemplateSelector);
  const isHomepageConfigurable = useSelector(getHomepageConfigurableToggle);

  useEffect(() => {
    if (!isLoggedIn || !userPrefTemplate || !allowChoseProfile) {
      setSelectedTemplate("default");
    } else {
      setSelectedTemplate((userPrefTemplate || "").toLowerCase());
    }
  }, [userPrefTemplate, isLoggedIn, allowChoseProfile]);

  const {
    pills,
    handleOnSectionVisibilityChanges,
    isLoading: areSectionsLoading,
    pillsDistanceFromTop
  } = useSections({
    enablePillsNav,
    componentsOrder: componentsOrder ?? []
  });

  const renderSection = useCallback(
    () => (
      <>
        {mainComponents.map((component, index) => {
          const handleVisibilityChange = (
            sectionWrapperRef: React.RefObject<HTMLSpanElement>,
            isComponentVisible: boolean
          ) => {
            handleOnSectionVisibilityChanges(
              component.props?.name,
              component.props?.title,
              sectionWrapperRef,
              isComponentVisible
            );
          };

          const title =
            component.props?.title || component.props?.componentSectionTitle;

          return (
            <StandaloneWrapper key={`main-component-${index.toString()}`}>
              <ComponentSection
                hasHeaderSpace={true}
                hasBackground={false}
                title={title}
                subtitle={component.props?.subtitle}
                qaLabel={`${component.props?.qaLabel}`}
                onSectionVisibilityChanges={handleVisibilityChange}
                seeAllLink={component.props?.seeAllLink}
              >
                {component}
              </ComponentSection>
            </StandaloneWrapper>
          );
        })}
      </>
    ),
    [mainComponents, handleOnSectionVisibilityChanges]
  );

  const renderDesktopComponents = () => (
    <>
      <TopSection data-qa-label="top-section">
        <Banner context={BannersConfigContext.HOME_PAGE} />
        {showHPTemplateSelector &&
          (isHomepageConfigurable || allowChoseProfile) &&
          isLoggedIn && (
            <HPTemplateSelector
              viewTypeSelector={VIEW_TYPE_SELECTOR.BANNER}
              onSettingsClick={() =>
                navigate("/preferences", {
                  state: { selectedTab: PreferencesTabEnum.HOMEPAGE_CONFIG }
                })
              }
              onDismiss={() =>
                setHPTemplateSelectorVisibility(accountNumber, false, dispatch)
              }
            />
          )}
        {renderComponentsDesk(
          templates,
          SectionConfigType.TOP,
          selectedTemplate
        )}
      </TopSection>
      <SectionsWrapper>
        <Main data-qa-label="main-section">
          {renderComponentsDesk(
            templates,
            SectionConfigType.MAIN,
            selectedTemplate
          )}
          <Bottom data-qa-label="bottom-section">
            {renderComponentsDesk(
              templates,
              SectionConfigType.BOTTOM,
              selectedTemplate
            )}
          </Bottom>
        </Main>
        <Side data-qa-label="side-section">
          {renderComponentsDesk(
            templates,
            SectionConfigType.SIDE,
            selectedTemplate
          )}
        </Side>
      </SectionsWrapper>
    </>
  );

  const renderMobileComponents = () =>
    hasComponentLoaded ? (
      <>
        <TemplateSelectorContainer>
          <TemplateSelectorBackground>
            {showHPTemplateSelector &&
              (isHomepageConfigurable || allowChoseProfile) &&
              isLoggedIn && (
                <>
                  <PreferencesModal />
                  <HPTemplateSelector
                    isMobile
                    onSettingsClick={() => {
                      dispatch(openPreferencesModalAction());
                      dispatch(
                        setSelectedTab(PreferencesTabEnum.HOMEPAGE_CONFIG)
                      );
                    }}
                    onDismiss={() =>
                      setHPTemplateSelectorVisibility(
                        accountNumber,
                        false,
                        dispatch
                      )
                    }
                  />
                </>
              )}
          </TemplateSelectorBackground>
        </TemplateSelectorContainer>
        <Banner context={BannersConfigContext.HOME_PAGE} />
        {enablePillsNav && (
          <PillsNav
            pills={pills}
            isLoading={areSectionsLoading}
            pillsDistanceFromTop={pillsDistanceFromTop}
          />
        )}
        {renderSection()}
        <Bottom data-qa-label="mobile-seo-footer">{seoFooterComponent}</Bottom>
      </>
    ) : (
      <HomePageMask />
    );
  return (
    <HomeContainer data-qa-label="home-container">
      {isMobile ? renderMobileComponents() : renderDesktopComponents()}
    </HomeContainer>
  );
};
export default Homepage;
