import {
  ComponentMapping,
  MobileTemplate,
  HomepageStoryblokConfig,
  HomepageStoryblokMobileConfig
} from "../types";
import componentsMapping from "../componentsMapping";

export const renderComponentsMobile = (
  userPrefTemplate: string,
  templates: HomepageStoryblokMobileConfig | HomepageStoryblokConfig
): {
  mainComponents: JSX.Element[];
  seoFooterComponent: JSX.Element[];
  componentsOrder?: { name: string; title: string }[];
} | null => {
  const components = templates?.[userPrefTemplate] as unknown as MobileTemplate;

  if (!components || !components.components) {
    return null;
  }

  const componentsOrder = components.components.map((component) => ({
    name: component.component,
    title: component.title
  }));

  const mainComponents = components.components
    .map((componentObj) => {
      const componentToRender = (componentsMapping as ComponentMapping)[
        componentObj.component as keyof ComponentMapping
      ]?.component;

      return componentToRender ? componentToRender({ ...componentObj }) : null;
    })
    .filter((component): component is JSX.Element => component !== null);

  const seoFooterComponent = components.seoFooter
    .map((footerObj) => {
      const seoComponent = (componentsMapping as ComponentMapping)[
        footerObj.component as keyof ComponentMapping
      ]?.component;

      return seoComponent ? seoComponent({ ...footerObj }) : null;
    })
    .filter((component): component is JSX.Element => component !== null);

  return { mainComponents, seoFooterComponent, componentsOrder };
};

export default renderComponentsMobile;
